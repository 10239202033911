import { Link, navigate } from "gatsby";
import React, { useState } from "react";
import {
  Navbar,
  Nav,
  Form,
  FormControl,
  Button,
  NavDropdown,
} from "react-bootstrap";
import { FaSearch } from "react-icons/fa";

interface Props {
  siteTitle: string;
}

const Header = ({ siteTitle }: Props) => {
  const [showDrop, setShowDrop] = useState({
    news: false,
    topics: false,
  });

  const handleShow = (name: string) => {
    const tabsToClose: any = {};
    // closing all tabs other than opened
    Object.keys(showDrop)
      .filter((key) => key !== name)
      .forEach((key) => (tabsToClose[key] = false));
    setShowDrop({ ...tabsToClose, [name]: true });
  };

  const handleClose = (name: string) => {
    setShowDrop({ ...showDrop, [name]: false });
  };
  const [search, setSearch] = useState("");

  const handleSubmit = () => {
    if (search !== "") {
      navigate(`/results`, { state: { query: search } });
    }
  };

  return (
    <header>
      <div>
        <Navbar expand="lg" fixed="top" className="boot-nav navbar-dark">
          <Navbar.Brand to="/" as={Link}>
            Flapmax <span>Blog</span>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="navbar-left">
              <NavDropdown
                title="News &amp; Stories"
                id="basic-nav-dropdown"
                show={showDrop.news}
                onMouseLeave={() => handleClose("news")}
                onMouseEnter={() => handleShow("news")}
              >
                <NavDropdown.Item to="/company-news" as={Link}>
                  Company News
                </NavDropdown.Item>

                <NavDropdown.Item to="/developer" as={Link}>
                  Developer
                </NavDropdown.Item>
                {/* 
                <NavDropdown.Item to="/" as={Link}>
                  Product Blog
                </NavDropdown.Item> */}
              </NavDropdown>

              <NavDropdown
                title="Browse Topics"
                id="basic-nav-dropdown"
                show={showDrop.topics}
                onMouseLeave={() => handleClose("topics")}
                onMouseEnter={() => handleShow("topics")}
              >
                <NavDropdown.Item as={Link} to="/natural-language-processing">
                  NLP
                </NavDropdown.Item>
                {/*
                <NavDropdown.Item to="/" as={Link}>
                  Cloud
                </NavDropdown.Item>
                <NavDropdown.Item to="/" as={Link}>
                  Data
                </NavDropdown.Item> */}
                <NavDropdown.Item to="/internet-of-things" as={Link}>
                  IoT
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
            <Nav className="navbar-right">
              <Form
                className="d-flex align-items-center"
                onSubmit={(e) => e.preventDefault()}
              >
                <FormControl
                  type="text"
                  placeholder="Search Articles"
                  className="mr-sm-2"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyDown={(e: { key: string }) => {
                    if (e.key === "Enter") {
                      handleSubmit();
                    }
                  }}
                />
                <Button
                  variant="outline-light"
                  className="d-flex align-items-center"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  <FaSearch />
                  Search
                </Button>
              </Form>
              <div className="px-lg-3 py-md-2 d-flex align-items-center gap-3">
                <h2 className="d-none d-lg-block text-light">|</h2>
                <Navbar.Brand
                  to="https://www.flapmax.com"
                  as={Link}
                  target="_blank"
                >
                  Home
                </Navbar.Brand>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </header>
  );
};

export default Header;
