import React from "react";
const flapmaxlogo = require("../images/faviconflapmax.png");

const Footer = () => {
  return (
    <footer className="footer footer-sm">
      <div className="footer-content">
        <ul className="list-inline social-circle text-center">
          <li className="list-inline-item">
            <a href="https://www.linkedin.com/company/flapmax/">
              <i className="zmdi zmdi-linkedin"></i>
            </a>
          </li>
          {/* <li className="list-inline-item">
            <a href="https://twitter.com/flapmaxhq">
              <i className="zmdi zmdi-twitter"></i>
            </a>
          </li> */}
        </ul>
      </div>

      <p className="text-center mb-0">© Flapmax 2024 All Rights Reserved</p>
      <img className="logo " src={flapmaxlogo} />
    </footer>
  );
};

export default Footer;
